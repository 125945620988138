import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { Link } from "gatsby"

import {Carousel} from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import './monitoring-networks.component.less';
import * as $ from "jquery";

import Station from "../../assets/img/monitoring-networks/img/station.jpg"
import One from "../../assets/img/monitoring-networks/icon/1.svg"
import Ellipse from "../../assets/img/monitoring-networks/icon/ellipse.svg"
import Two from "../../assets/img/monitoring-networks/icon/2.svg"
import Three from "../../assets/img/monitoring-networks/icon/3.svg"
import Four from "../../assets/img/monitoring-networks/icon/4.svg"
import Five from "../../assets/img/monitoring-networks/icon/5.svg"
import Six from "../../assets/img/monitoring-networks/icon/6.svg"
import GalleryDesktop from "../../assets/img/monitoring-networks/img/gallery-desktop.jpg"
import GalleryTablet from "../../assets/img/monitoring-networks/img/gallery-tablet.jpg"
import GalleryMobile from "../../assets/img/monitoring-networks/img/gallery-mobile.jpg"
import ModelPoster from "../../assets/img/monitoring-networks/img/model-poster.png"
import SoftwarePoster from "../../assets/img/monitoring-networks/img/software-poster.jpg"
import Model from "../../assets/video/monitoring-networks/model.mp4"
import Software from "../../assets/video/monitoring-networks/software.mp4"
import SEO from "../seo";
import {useI18next} from "gatsby-plugin-react-i18next";

const MonitoringNetworksComponent = () => {
    const { t, i18n } = useTranslation()
    const { originalPath } = useI18next()

    useEffect(() => {
        $('a[href^="#"]').click(function () {
            let elementClick = $(this).attr("href");
            let destination = $(elementClick).offset().top - 100;
            $('html, body').animate({
                scrollTop: destination
            }, 450);
            return false;
        });
    });

    return (
        <div>
            <SEO description={t("metaDescriptionMonitoringNetworks")} lang={i18n.language} title={t("titleMonitoringNetworks")} link={"https://cityair.ru/"} />
            <div className="wrapper_page">
                <div className="platform_block_main">
                    <div className="platform_block_main__container">
                        <div className="container">
                            <div className="row">
                                <h1 className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-9 col-fhd-9 col-rt-9 platform_block_main__title">
                                    <span className="platform_block_main__title_size-xl">CityAir</span>
                                    Cоздаём <span className="platform_block_main__title_weight-bold">сети и сервисы контроля <br/> качества атмосферного воздуха</span> <br/>для городов и промпредприятий 
                                </h1>
                            </div>
                            <div className="row">
                                <div className="platform_block_main__description">
                                    <div className="platform_block_main__description-items">
                                        <div className="platform_block_main__description-item">
                                            <span className="platform_block_main__description-item_weight-bold">Создаём с нуля или <br/>дополняем существующие</span> <br/>сети мониторинга 
                                        </div>
                                        <div className="platform_block_main__description-item">
                                            Осуществляем <span className="platform_block_main__description-item_weight-bold">монтаж, пусконаладку, <br/>поверку оборудования и техническое <br/>сопровождение</span>
                                        </div>
                                        <div className="platform_block_main__description-item">
                                            Делаем это <span className="platform_block_main__description-item_weight-bold">в соответствии <br/>с требованиями государственных <br/>стандартов</span>
                                        </div>
                                    </div>
                                    <a href="#sec_4" className="btn platform_block_main__btn">
                                        <span className="btn__text btn_gradient-blue">{t("contactUs")}</span>
                                        <span className="btn__shadow"></span>
                                    </a>
                                </div>
                            </div>    
                        </div>
                    </div>
                </div>

                <div className="wrapper_container">
                    <div className="platform-block__gallery">
                        <img alt="Галерея CityAir" src={GalleryDesktop} className="platform-block__gallery-image platform-block__gallery-image_display-desktop" />
                        <img alt="Галерея CityAir" src={GalleryTablet} className="platform-block__gallery-image platform-block__gallery-image_display-tablet" />
                        <img alt="Галерея CityAir" src={GalleryMobile} className="platform-block__gallery-image platform-block__gallery-image_display-mobile" />
                    </div>
                </div>

                <div className="platform_title_block">
                    {t("keyNetwork")}
                </div>
                <div className="wrapper_container" id="sec_1">
                    <div className="platform_block_2__container">
                        <div className="container">
                            <div className="row">
                                <div className="col-0 col-sm-0 col-md-0 col-lg-7 col-xl-7 col-xga-7 col-fhd-7 col-rt-7" />
                                <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5">
                                    <div className="color_purple platform_block__number">
                                        01. {t("equipment")}
                                    </div>
                                    <div className="platform_block__title">
                                        {t("airQualityMonitoring")}
                                    </div>
                                    <img alt="" src={Station} className="img_platform_station-mobile" />
                                    <img alt="" src={Station} className="img_platform_station-ipad" />
                                    <div className="platform_block__list color_black_light">
                                        <div className="platform_block__info-important">
                                            Произведено в Российской Федерации
                                        </div>
                                        <ul>
                                            <li className="content_container__list-li ">
                                                {t("compactSize")}
                                            </li>
                                            <li className="content_container__list-li">
                                                {t("transferOfMeasurement")}
                                            </li>
                                            <li className="content_container__list-li">
                                                {t("workInAWide")}
                                            </li>
                                            <li className="content_container__list-li">
                                                {t("modularArchitecture")} (PM2.5, PM10, CO, NO<sub>2</sub>, O<sub>3</sub>, SO<sub>2</sub>, H<sub>2</sub>S {t("etc")})
                                            </li>
                                            <li className="content_container__list-li">
                                                {t("integrationEquipment")}
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="btn btn_position_mobile-center">
                                        <Link className="btn__link" to={"/" + i18n.language + "/equipment"}>
                                            <span className="btn__text btn_gradient-blue btn_size-standard">Больше об оборудовании</span>
                                            <span className="btn__shadow btn__shadow_size-standard"></span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="img_block img_block-ipad img_block-left">
                                <img alt="" src={Station} className="img_platform_station" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrapper_container" id="sec_2">
                    <div className="platform_block_3__container">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5">
                                    <div className="color_purple platform_block__number">
                                        02. {t("simulation")}
                                    </div>
                                    <div className="platform_block__title">
                                        {t("simulationAndForecasting")}
                                    </div>
                                    <div className="video-block video-block_position-relative video-block_display-mobile">
                                        <div className="video-block__mac-wrapper">
                                            <div className="video-block__mac-elements">
                                                <span className="video-block__mac-element video-block__mac-element_color-red"></span>
                                                <span className="video-block__mac-element video-block__mac-element_color-yellow"></span>
                                                <span className="video-block__mac-element video-block__mac-element_color-green"></span>
                                            </div>
                                            <div className="video-block__video-wrapper">
                                                <video loop autoPlay muted playsInline poster={ModelPoster} className="video-block__video" >
                                                    <source src={Model} type="video/mp4"/>
                                                </video>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="platform_block__list color_black_light">
                                        <ul>
                                            <li className="content_container__list-li ">
                                               {t("airPollutionHeatmap")}
                                            </li>
                                            <li className="content_container__list-li ">
                                               {t("constructionTrajectories")}
                                            </li>
                                            <li className="content_container__list-li">Определение вклада источников выбросов в&nbsp;экологию региона</li>
                                            <li className="content_container__list-li">
                                                {t("forecastFor")}
                                            </li>
                                            <li className="content_container__list-li">
                                                {t("storeArchives")}
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="btn btn_position_mobile-center">
                                        <Link className="btn__link" to={"/" + i18n.language + "/modeling"}>
                                            <span className="btn__text btn_gradient-blue btn_size-standard">Больше о моделировании</span>
                                            <span className="btn__shadow btn__shadow_size-standard"></span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-0 col-sm-0 col-md-0 col-lg-7 col-xl-7 col-xga-7 col-fhd-7 col-rt-7"/>
                            </div>
                        </div>
                        <div className="video-block video-block_position-absolute video-block_position-right video-block_display-desktop">
                            <div className="video-block__mac-wrapper">
                                <div className="video-block__mac-elements">
                                    <span className="video-block__mac-element video-block__mac-element_color-red"></span>
                                    <span className="video-block__mac-element video-block__mac-element_color-yellow"></span>
                                    <span className="video-block__mac-element video-block__mac-element_color-green"></span>
                                </div>
                                <div className="video-block__video-wrapper">
                                    <video loop autoPlay muted playsInline poster={ModelPoster} className="video-block__video" >
                                        <source src={Model} type="video/mp4"/>
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrapper_container" id="sec_3">
                    <div className="platform_block_4__container">
                        <div className="container">
                            <div className="row">
                                <div className="col-0 col-sm-0 col-md-0 col-lg-7 col-xl-7 col-xga-7 col-fhd-7 col-rt-7" />
                                <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5">
                                    <div className="color_purple platform_block__number">
                                        03. {t("software")}
                                    </div>
                                    <div className="platform_block__title">
                                        {t("dataHandlingTools")}
                                    </div>
                                    <div className="video-block video-block_position-relative video-block_display-mobile">
                                        <div className="video-block__mac-wrapper">
                                            <div className="video-block__mac-elements">
                                                <span className="video-block__mac-element video-block__mac-element_color-red"></span>
                                                <span className="video-block__mac-element video-block__mac-element_color-yellow"></span>
                                                <span className="video-block__mac-element video-block__mac-element_color-green"></span>
                                            </div>
                                            <div className="video-block__video-wrapper">
                                                <video loop autoPlay muted playsInline poster={SoftwarePoster} className="video-block__video" >
                                                    <source src={Software} type="video/mp4"/>
                                                </video>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="platform_block__info-important">
                                        Программное обеспечение включено в&nbsp;<a target="_blank" href="https://reestr.digital.gov.ru/reestr/918428/" className="platform_block__info-important__link">Единый реестр российских программ</a> для ЭВМ и БД​
                                    </div>
                                    <div className="platform_block__list color_black_light">
                                        <ul>
                                            <li className="content_container__list-li ">
                                                {t("mapAndGraphs")}
                                            </li>
                                            <li className="content_container__list-li">
                                                {t("automaticReportsAnd")}
                                            </li>
                                            <li className="content_container__list-li">
                                                {t("dataImportEither")}
                                            </li>
                                            <li className="content_container__list-li">
                                                {t("accessViaSecure")}
                                            </li>
                                            <li className="content_container__list-li">
                                                {t("standaloneSolutionsFor")}
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="btn btn_position_mobile-center">
                                        <Link className="btn__link" to={"/" + i18n.language + "/software"}>
                                            <span className="btn__text btn_gradient-blue btn_size-standard">Больше об инструментах</span>
                                            <span className="btn__shadow btn__shadow_size-standard"></span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="video-block video-block_position-absolute video-block_position-left video-block_display-desktop">
                                <div className="video-block__mac-wrapper">
                                    <div className="video-block__mac-elements">
                                        <span className="video-block__mac-element video-block__mac-element_color-red"></span>
                                        <span className="video-block__mac-element video-block__mac-element_color-yellow"></span>
                                        <span className="video-block__mac-element video-block__mac-element_color-green"></span>
                                    </div>
                                    <div className="video-block__video-wrapper">
                                        <video loop autoPlay muted playsInline poster={SoftwarePoster} className="video-block__video" >
                                            <source src={Software} type="video/mp4"/>
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="platform_title_block_2 platform_block__ipad_flex">
                        {t("howWeWork")}
                    </div>
                </div>
                <div className="wrapper_platform_block_5__container">
                    <div className="container">
                        <div className="img_platform_line-ipad" />
                    </div>
                    <div className="wrapper_container container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12">
                                <div className="img_platform_line img_platform_line-right" />
                            </div>
                        </div>
                    </div>
                    <div className="wrapper_container">
                        <div className="platform_block_5__container">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xga-4 col-fhd-4 col-rt-4 platform_block_5__ipad_block">
                                        <div className="platform_block_5__number">1</div>
                                        <div className="platform_block_5__mobile_number">
                                            <img alt="" src={One} />
                                        </div>
                                        <div
                                            className="platform_block_5__ellipse">
                                            <img alt="" src={Ellipse} />
                                        </div>
                                        <div className="platform_block_5__description platform_block__list-one_string">
                                            {t("negotiationOfRequirements")}
                                        </div>
                                    </div>
                                    <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xga-4 col-fhd-4 col-rt-4 platform_block_5__ipad_block">
                                        <div className="platform_block_5__number">2</div>
                                        <div className="platform_block_5__mobile_number">
                                            <img alt="" src={Two} />
                                        </div>
                                        <div
                                            className="platform_block_5__ellipse">
                                            <img alt="" src={Ellipse} />
                                        </div>
                                        <div className="platform_block_5__description platform_block__list-one_string">
                                            {t("networkDesignPreparation")}
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xga-4 col-fhd-4 col-rt-4 platform_block_5__ipad_block">
                                        <div className="platform_block_5__number">3</div>
                                        <div
                                            className="platform_block_5__mobile_number">
                                            <img alt="" src={Three} />
                                        </div>
                                        <div
                                            className="platform_block_5__ellipse">
                                            <img alt="" src={Ellipse} />
                                        </div>
                                        <div className="platform_block_5__description platform_block__list-one_string">
                                            {t("manufacturingOfMonitoring")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper_container container">
                        <div className="row">
                            <div
                                className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xga-8 col-fhd-8 col-rt-8">
                                <div className="img_platform_line img_platform_line-left" />
                            </div>
                        </div>
                    </div>
                    <div className="wrapper_container">
                        <div className="platform_block_5__container-end">
                            <div className="container">
                                <div className="row">
                                    <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xga-3 col-fhd-3 col-rt-3 platform_block_5__ipad_block">
                                        <div
                                            className="platform_block_5__number">4
                                        </div>
                                        <div
                                            className="platform_block_5__mobile_number">
                                            <img alt="" src={Four} />
                                        </div>
                                        <div className="platform_block_5__ellipse">
                                            <img alt="" src={Ellipse} />
                                        </div>
                                        <div className="platform_block_5__description platform_block__list-one_string">
                                            {t("softwareProductPreparation")}
                                        </div>
                                    </div>
                                    <div className="col-1 col-sm-1 col-md-1 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 platform_block_7__string-enable" />
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xga-3 col-fhd-3 col-rt-3 platform_block_5__ipad_block">
                                        <div className="platform_block_5__number">5</div>
                                        <div className="platform_block_5__mobile_number">
                                            <img alt="" src={Five} />
                                        </div>
                                        <div
                                            className="platform_block_5__ellipse">
                                            <img alt="" src={Ellipse} />
                                        </div>
                                        <div className="platform_block_5__description platform_block__list-one_string">
                                            {t("installationOfEquipment")}
                                        </div>
                                    </div>
                                    <div className="col-1 col-sm-1 col-md-1 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 platform_block_7__string-enable"/>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xga-3 col-fhd-3 col-rt-3 platform_block_5__ipad_block">
                                        <div className="platform_block_5__number">6</div>
                                        <div className="platform_block_5__mobile_number">
                                            <img alt="" src={Six} />
                                        </div>
                                        <div className="platform_block_5__ellipse">
                                            <img alt="" src={Ellipse} />
                                        </div>
                                        <div className="platform_block_5__description platform_block__list-one_string">
                                            {t("serviceSupportRegular")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrapper_container">
                    <div className="platform_block_7__container">
                        <div className="container">
                            <div className="row platform_block__ipad_flex">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xga-7 col-fhd-7 col-rt-7 platform_block_7__title platform_block__ipad_flex">
                                    {t("howCityAirIsProvided")}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-1 col-sm-1 col-md-1 col-lg-0 col-xl-0 col-xga-0 col-fhd-0 col-rt-0 platform_block_7__string-disable"/>
                                <div className="col-11 col-sm-11 col-md-11 col-lg-4 col-xl-4 col-xga-4 col-fhd-4 col-rt-4 platform_block_7__string">
                                    <div className="platform_block_5__description platform_block_5__description-7">
                                        <div className="platform_block_7__point"/>
                                        <div>{t("saleOfA")}</div>
                                    </div>
                                </div>
                                <div className="col-1 col-sm-1 col-md-1 col-lg-0 col-xl-0 col-xga-0 col-fhd-0 col-rt-0 platform_block_7__string-disable"/>
                                <div className="col-11 col-sm-11 col-md-11 col-lg-4 col-xl-4 col-xga-4 col-fhd-4 col-rt-4 platform_block_7__string">
                                    <div className="platform_block_5__description platform_block_5__description-7">
                                        <div className="platform_block_7__point"/>
                                        <div>{t("airQualityMonitoringAs")}</div>
                                    </div>
                                </div>
                                <div className="col-1 col-sm-1 col-md-1 col-lg-0 col-xl-0 col-xga-0 col-fhd-0 col-rt-0 platform_block_7__string-disable"/>
                                <div className="col-11 col-sm-11 col-md-11 col-lg-4 col-xl-4 col-xga-4 col-fhd-4 col-rt-4 platform_block_7__string">
                                    <div className="platform_block_5__description platform_block_5__description-7">
                                        <div className="platform_block_7__point"/>
                                        <div>{t("monitoringAs")}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrapper_container wrapper_container__background_request">
                    <div className="platform_block_8__container">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-11 col-lg-6 col-xl-6 col-xga-6 col-fhd-6 col-rt-6 platform_block_8__title" id="sec_4">
                                    {t("stillHaveQuestions")}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12 platform_block_8__description">
                                    {t("youCanCall")} <span className="color_purple platform_block_8_font_medium">
                                    <a href={'tel:' + t("phoneNumber").replace(/\s/g, '')}>{t("phoneNumber")}</a>
                                    </span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12 platform_block_8__description">
                                    {t("orWrite")} <a className="color_purple platform_block_8_font_medium" href="mailto:info@cityair.ru">{t("mail")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

MonitoringNetworksComponent.propTypes = {}

MonitoringNetworksComponent.defaultProps = {}

export default MonitoringNetworksComponent
